import React from "react";

export default function AccessibilityCompliance(props) {
  return (
    <div className="tabs-container container">
      <div className="contactUs-container pl-0 row">
        <div className="contactUs-subContainer">

          <div className="terms-of-use-head p-4">
            <b className="p-2 dib"> ACCESSIBILITY COMPLIANCE </b>
          </div>
          <div className="container-body p-4 mt-2">
            <section className="block-elm px-2 mb-3">
              <p>
                Thank you for visiting the product of the Conduent, Inc. The Conduent is dedicated to providing users with various types of information from the Department of Health and Human Services and other medical agencies. It is our intent that all users receive easy and efficient access to our web site. This site complies with Section 508 Guidelines outlined by the Federal government. Section 508 compliance is completely satisfied by WCAG (2.0) Priorities 1 and 2. This site has a conformance level of "AA" meaning that all Priority 1 and 2 checkpoints are satisfied.
              </p>
            </section>
            <div className="block-elm px-2 mb-3">
              <ul className="p-0 ml-3">
              <li className="pb-3">
              If you are unable to view PDFs, <a href="https://get.adobe.com/reader/" target="_blank">please download Adobe Reader</a>
              </li>
              </ul>

              <p className="mt-4"> If you have difficulty accessing the information on our site, You should first contact your site administrator. If you require further assistance, you may contact participant inquiries (800) 392-2161, Monday through Friday, 8:00 AM - 5:00 PM Central Standard Time. </p>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
